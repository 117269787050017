import React from "react";
import {motion} from "framer-motion";
import styles from "./animation.module.css";

const RotateLogo=(props)=>{
    
    return(
        <motion.img src={props.logo} animate = {{rotate: 360}}  transition={{ repeat: Infinity, duration: 2, ease:"linear" }} className={styles.applogohome} >
        </motion.img>
    )
}

export default RotateLogo;