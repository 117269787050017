import React,{useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';

const BasePage =() =>
{
    
useEffect(() => {
    document.title = 'RSD IT Services';
  }, []);

    return(   
    <> 
    <Header pagename="Home"></Header>
    <Home></Home>
    </>
    )
}

export default BasePage;        