import { Button, styled } from "@mui/material";
import { motion } from "framer-motion";

const RSDButtonComponent =(props) =>
{
    console.log(props);
    const RSDbutton = styled (Button)(()=>
        ({
            fontFamily:'Audiowide',
            color:'#fff',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#333',
            },
        }));
    return( <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.3 }}>
        <RSDbutton variant="text">{props.page}</RSDbutton></motion.div>)       
}

export default  RSDButtonComponent;